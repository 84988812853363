import React, { Component, useState, Suspense } from "react";
import './Home.scss';

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import Image from "react-image-webp";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import Modal from "@material-ui/core/Modal";
// import Button from "@material-ui/core/Button";

// import RecentProject from "./RecentProject/RecentProject";
import ReviewsSlider from '../Common/ReviewsSlider/ReviewsSlider';
import GallerySlider from '../Common/ReviewsSlider/GallerySlider';
import Station from "../Common/Stations";
// import VideoPreview from "../Common/VideoPreview";
import HomeBanner2 from "../Common/BannerParts/HomeBanner2";
import MobileHomeBanner from "../Common/BannerParts/MobileHomeBanner";

// import ImageOne from "../Assets/Images/image_1.jpg";
import ImageTwo from "../Assets/Images/bathroom.jpg";
// import ImageOneWebP from "../Assets/Images/image_1.webp";
import bestHouz from "../Assets/Images/bestHouz2021.png";
import BILDLogo from "../Assets/Images/bild-logo-orig.jpeg";
import RenomarkLogo from "../Assets/Images/renomark_logo.png";
import HomeStars from "../Assets/Images/best-of-homestars.png";
import star from "../Assets/Images/star.png";
// import commas from "../Assets/Images/commas.png";
import google from "../Assets/Images/google-reviews.png";
import CloseIcon from '@mui/icons-material/Close';
// import video_prev from "../Assets/Images/video_prev.jpg";
// import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import filesFetcher from '../Common/FilesFetcher';

const getOfferData = async (setOffer) => {
  const offers = await filesFetcher.fetchPromoOffer();
  const offerData = offers.find(item => item.value === "Basements");

  if (offerData)
    setOffer(offerData.banner);
};


const Banner = () => {
  const [offer, setOffer] = useState(null);

  if (!offer) {
    getOfferData(setOffer);
    return null;
  }

  return (
    <div className="promoStamp">
      <button 
        onClick={(e)=> { 
          e.currentTarget.parentElement.style.display = 'none'; 
          e.currentTarget.parentElement.parentElement.classList.add('closedPromo'); }} 
        className="closeBtn"
      >
        <CloseIcon/>
      </button>
      <p className="topPar">
        <span>
          Save {offer.amount} on Basement Renovations
        </span>
      </p>

      <p className="middleOffer desk">
      <strong>No</strong> Money Down <br/>
      <strong>No</strong> Interest <br/>
      <strong>No</strong> Payments for 1 Year*
      </p>
      <p className="middleOffer mobile">
      <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
      </p>

         { offer.extra ?
          <p style={{ textAlign: 'center', paddingTop: '0' }} className="middleOffer image desk">
           <strong>Take an extra <br/>{offer.extra} OFF your project</strong>
          </p> : null }

          { offer.extra ?
          <p style={{ textAlign: 'center', paddingTop: '0' }} className="middleOffer image mobile">
           <strong>Take an extra {offer.extra} OFF your project</strong>
          </p> : null }
    
        <Link sx={{ color: '#fff', fontWeight: 700 }} to="/estimate?offer=1" className="blue-dark-windows-btn">
        <div style={{ color: '#fff', fontWeight: 700 }} className="button">
          See Offer
        </div>
        </Link>

      <span className="endPart">Offer ends {offer.end}</span>
    </div>
  );
};

class OurProcess extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta
            name="robots"
            content="index"
          />
        </Helmet>
        <div style={{ paddingBottom: '40px' }} className="our-process">
          <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Our Process
          </h2>
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <ol>
                <li>
                  <span>1</span> Video Consultation
                </li>
                <li>
                  <span>2</span> Select Finishes
                </li>
                <li>
                  <span>3</span> Technical Walk-through Inspection (Site Visit)
                </li>
              </ol>
            </div>
            <div className="col-12 col-md-12 col-sm-12">
              <ol>
                <li>
                  <span>4</span> The Build
                </li>
                <li>
                  <span>5</span> Project Complete
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div>
          <a
            className="btn red-btn desktop-none"
            href="/our-work"
            style={{
              width: "100%",
              fontWeight: "900",
              fontSize: "1rem",
              display: "bdatak",
              marginTop: "1rem",
            }}
          >
            View our recent work
          </a>
        </div>
        <div className="">
          <a
            className="btn red-btn mobile-none hvr-sweep-to-right"
            href="/our-work"
            style={{
              width: "100%",
              fontWeight: "900",
              fontSize: "1.5rem",
              display: "bdatak",
              marginTop: "1rem",
            }}
          >
            View our recent work
          </a>
        </div>
      </div>
    );
  }
}

class ReviewStars extends Component {
  render() {
    return (
      <div className="row" style={{ margin: "0.01rem" }}>
        <img style={{ height: "10px" }} src={star} />
        <img style={{ height: "10px" }} src={star} />
        <img style={{ height: "10px" }} src={star} />
        <img style={{ height: "10px" }} src={star} />
        <img style={{ height: "10px" }} src={star} />
      </div>
    );
  }
}

class Home extends Component {
  static propTypes = {};

  state = { open: true };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    
    return (
      <>
        <Helmet>
          <title>AGM Basements | Basement Renovations</title>
        </Helmet>
        {/* <div className={"mobile-none"}>
          <EmergencyBanner />
        </div> */}
        <div className={"mobile-none"}>
          <HomeBanner2 />
        </div>
        <div className={"desktop-none sticky-nav-top-margin"}>
          <MobileHomeBanner />
        </div>

        <Banner/>
        <Station />

        {/* <VideoPreview /> */}

          <br/><br/>

        <div className="sticky-nav-top-margin mobile-none">
          <h1 className="section-title" style={{ marginTop: "0" }}>
            What our clients say about us
          </h1>
          <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
            <img src={google} style={{ width: "12rem" }} />
          </div>
            <Suspense fallback={<div>Loading...</div>}>
              <div
                id="design-center-slider"
                className="design-center-slider container mb-4"
              >
                <ReviewsSlider />
                <div
                  className="free-consultation-btn"
                  style={{ textAlign: "center" }}
                >
                  <a
                    href="https://www.google.com/search?q=agm+renovations+google+reviews&oq=agm+renovations+google+reviews&aqs=chrome..69i57j0l4j69i60l3.3523j0j4&sourceid=chrome&ie=UTF-8#lrd=0x882b2dd8ee9962bd:0xd03d1da943a37695,1"
                    target="_blank"
                    className="btn light-blue-sq-btn hvr-grow"
                    style={{
                      marginTop: "2.5rem",
                      padding: "20px 30px",
                      fontSize: "12px",
                    }}
                  >
                    More Testimonials
                  </a>
                </div>
              </div>
            </Suspense>

            <div style={{ width: '100%', textAlign: 'center', textAlign: 'center', color: '#01afda', fontSize: '20px', textDecoration: 'underline', padding: '10px 0 20px 0' }}>
              <Link style={{color: '#01afda'}} to="/reviews">
                agm renovations reviews
              </Link>
          </div>

          <div className="home-services">
            <div
              style={{
                background:
                  "linear-gradient(89.59deg, #FFFFFF 0%, #F3F3F3 100%, #F3F3F3 100%, #F3F3F3 100%)",
              }}
            >
              <div className="service service-image-left">
                <div className="left">
                  <div className="video-container">
                    <iframe
                      width="690"
                      height="460"
                      src="https://www.youtube.com/embed/Jw5UeP57TP4"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="video"
                    ></iframe>
                  </div>
                </div>

                <div className="right">
                  <h2 className="stroke">Basement Renovations</h2>
                  <p>
                    As the first company to specialize exclusively in basement
                    renovation, AGM Basements has listened to customers for over
                    20 years, and has developed a tried and true process focused
                    on you. We’re here to help you realize your dream basement
                    to make the best decisions for your investment, for your
                    home and your family.
                  </p>
                  <div className="sub-services">
                    <ul>
                      <li>
                        <Link to="/our-work">Recreation </Link>
                      </li>
                      <li>
                        <Link to="/our-work">Offices </Link>
                      </li>
                      <li>
                        <Link to="/our-work">
                          Fully Functional Living Space{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/our-work">Sauna and Workout Space </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                background:
                  "linear-gradient(89.59deg, #F3F3F3 0%, #FFFFFF 100%, #F3F3F3 100%, #F3F3F3 100%)",
                marginBottom: "1rem",
              }}
            >
              <div className="service service-image-right">
                <div className="left">
                  <img src={ImageTwo} alt="Interior Finishing" />
                </div>

                <div className="right">
                  <h2 className="stroke">Bathroom Renovations</h2>
                  <p>
                    Meet with a design expert to plan the perfect bathroom
                    renovation for your family’s home and get a quote on your
                    project. Working together, we will create a convenient
                    renovation schedule that suits your specific needs and
                    requirements. Our installation experts will get to work
                    making your custom bathroom design a reality, staying on
                    schedule and under budget.
                  </p>
                  <div className="sub-services">
                    <ul>
                      <li>
                        <Link to="/our-work">Vanities </Link>
                      </li>
                      <li>
                        <Link to="/our-work">Custom Showers </Link>
                      </li>
                      <li>
                        <Link to="/our-work">Tiles </Link>
                      </li>
                      <li>
                        <Link to="/our-work">Fixtures </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            {/* <RecentProject /> */}
          <h2 className="section-title" style={{ marginTop: "0" }}>
            See our Work
          </h2>
            <Suspense fallback={<div>Loading...</div>}>
              <div
                id="design-center-slider"
                className="design-center-slider container mb-4"
              >
                {/* <ReviewsSlider /> */}
                <GallerySlider />
              </div>
            </Suspense>

            <div className="get-quote-btn" style={{ marginTop: "3rem" }}>
              <Link to="/estimate" className="btn red-btn-2 hvr-sweep-to-right">
                Get a Free Quote
              </Link>
            </div>
          </div>
        </div>
        <div
          className="mobile-none home-info-container sticky-nav-top-margin"
          style={{ backgroundColor: "#fff" }}
        >
          <div className={"home-recent-work"} style={{ marginTop: "5rem" }}>
            <div className={"home-warranty-2"}>
              <div className="home-warrany-icon">
                <div />
              </div>
              <p>5 Year</p>
              <span>Baseboard to Baseboard Warranty</span>
              <p>Warranty</p>
            </div>
            <div className={"our-process-container-2"}>
              <div className={"our-process-container"}>
                <OurProcess />
              </div>
              <h1>Highest Rated Basement Renovators</h1>
            </div>
            <div className="container">
              <div
                className="row justify-content-center"
                style={{ marginTop: "-6rem" }}
              >
                <div className="col-3 col-md-3" style={{ textAlign: "center" }}>
                  <img src={HomeStars} style={{ height: "10rem" }} />
                </div>
                <div className="col-3 col-md-3" style={{ textAlign: "center" }}>
                  <img src={BILDLogo} style={{ height: "10rem" }} />
                </div>
                <div className="col-3 col-md-3" style={{ textAlign: "center" }}>
                  <img src={bestHouz} style={{ height: "10rem" }} />
                </div>
                <div className="col-3 col-md-3" style={{ textAlign: "center" }}>
                  <img src={RenomarkLogo} style={{ height: "10rem" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="desktop-none home-info-container sticky-nav-top-margin">
          <div className="mb-4">
            <h2 style={{ textAlign: "center" }}>
              Highest Rated Basement Renovators
            </h2>
            <img className="desktop-none best-houz" src={bestHouz} />
            <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
              <img src={google} style={{ width: "8rem" }} />
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <div
                id="design-center-slider"
                className="design-center-slider container mb-4"
              >
                <ReviewsSlider />
                <div
                  className="free-consultation-btn"
                  style={{ textAlign: "center" }}
                >
                  <a
                    href="https://www.google.com/search?q=agm+renovations+google+reviews&oq=agm+renovations+google+reviews&aqs=chrome..69i57j0l4j69i60l3.3523j0j4&sourceid=chrome&ie=UTF-8#lrd=0x882b2dd8ee9962bd:0xd03d1da943a37695,1"
                    target="_blank"
                    className="btn light-blue-sq-btn hvr-grow"
                    style={{
                      marginTop: "2.5rem",
                      padding: "10px 15px",
                      fontSize: "10px",
                    }}
                  >
                    See More
                  </a>
                </div>
              </div>
            </Suspense>
          </div>
          <div className="home-info-area">
            <div style={{ fontSize: '1.4em', marginBottom: '17px'}}><span className="sub-head">
                Serving Ontario for over 20 years
            </span></div>
            <h2>We transform your dreams into reality.</h2>
            <p>
              AGM Basement's renovation experts provide quality basement
              finishing services throughtout the GTA and surrounding areas
            </p>
          </div>
          <div className={"home-recent-work"} style={{ marginBottom: "1rem" }}>
            <div className={"home-warranty"}>
              <div className="home-warrany-icon">
                <div />
              </div>
              <p>5 Year</p>
              <span>Baseboard to Baseboard Warranty</span>
              <p>Warranty</p>
            </div>
            <div className={"our-process-container"}>
              <OurProcess />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
