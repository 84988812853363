import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
// import { isWebpSupported } from "react-image-webp/dist/utils";
// import HomeBannerImage from "../../Assets/Images/DSC1073.jpg";
// import HomeBannerImageWebP from "../../Assets/Images/DSC1073.webp";

//import HomeBannerImage from '../../Assets/Images/basement_final.jpg';
//import HomeBannerImageWebP from '../../Assets/Images/basement_final.webp';
import HomeBannerImage from '../../Assets/Images/AUA_6102_w.jpg';
// import HomeBannerImageWebP from '../../Assets/Images/Basement035.jpg';

// import { Link } from "react-router-dom";
// import AnnaHarrisImage from "../../Assets/Images/anna-harris.png";
import promo2 from '../../Assets/Images/basementsspecoffer.gif';

/* eslint-disable */
function EstimateHeader(props) {
  const [source, setSource] = useState('');

  const theSource = localStorage.getItem('vars');

  useEffect(() => {
    const parsedSource = theSource && JSON.parse(theSource).agmsource;

    const possibleSources = [
      '1310 news',
      '580 AM',
      'AM900 (Hamilton)',
      'CHCH TV',
      'CHML',
      'CKTB',
      'Company vehicle',
      'CP24',
      'CTV',
      'Home Show',
      'AdWords',
      'Internet Search',
      'Magazine/flier',
      'News Talk 610 (Niagara)',
      '680 News',
      'Referral from previous client (CSR team will collect contact info once connected to the customer)',
      'Social Media',
      'Facebook',
      'Instagram',
      'YouTube',
      'Pinterest',
      '3C Contact Services.',
      'Nexa Call Center',
      'Word of mouth'
    ];

    if (possibleSources.includes(decodeURI(parsedSource))) {
      setSource(decodeURI(parsedSource));
    };
    // setSource();
  }, [theSource]);

  return (
    <section
      id="home-banner"
      className="estimate-header-contain home"
      style={{
        backgroundImage: `url(${ 'isWebpSupported()' ? HomeBannerImage : HomeBannerImage })`
      }}
    >
                    {/* <Link to="/estimate">
                      <img className="promo2" src={promo2}/>
                    </Link> */}
      

      <div className="banner-cover">
        <div className="banner-content banner-content--home estimate d-flex">
          <div className="left">
            <h1>Basement Renovations <span>Done Right!</span></h1>
            <div className="contact-form-2">
              <h3>Get a free quote</h3>
              <div className="form">
              <form
                action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
                id="estimateform"
              >
                <div className="estimate-inputs">
                  <input type="hidden" name="oid" value="00D5Y000002U1by" />
                  <input type="hidden" name="Form_Type__c" value="home" />
                  <input
                    type="hidden"
                    name="retURL"
                    value="https://agmbasements.com/thank-you"
                  />

                  {source && (
                    <input
                      id="lead_source"
                      name="lead_source"
                      type="hidden"
                      value={source || ""}
                    />
                  )}

                  <input
                    type="hidden"
                    id="Renovation_Type__c"
                    name="Renovation_Type__c"
                    value="Basement"
                  />

                  <input
                    type="hidden"
                    name="Lead_Origin__c"
                    value="Web"
                  />

                  {/* NOTE: These fields are optional debugging elements. Please uncomment
                  these lines if you wish to test in debug mode.
                  <input type="hidden" name="debug" value="1" />
                  <input type="hidden" name="debugEmail" value="sadish@integrate2cloud.com" /> */}
                  <div className="input-holder full-name">
                    <input
                      id="first_name"
                      maxLength="40"
                      name="first_name"
                      size="20"
                      type="text"
                      placeholder="First Name"
                      required
                    />
                  </div>

                  <div className="input-holder full-name">
                    <input
                      id="last_name"
                      maxLength="80"
                      name="last_name"
                      size="20"
                      type="text"
                      placeholder="Last Name"
                      required
                    />
                  </div>

                  <div className="input-holder full-name">
                    <input
                      id="email"
                      maxLength="80"
                      name="email"
                      size="20"
                      type="text"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div className="input-holder full-name">
                    <input
                      id="phone"
                      maxLength="40"
                      name="phone"
                      size="20"
                      type="text"
                      placeholder="Phone"
                      required
                    />
                  </div>

                  <div className="input-holder full-name">
                    <input
                      id="city"
                      maxLength="40"
                      name="city"
                      size="20"
                      type="text"
                      placeholder="City"
                      required
                    />
                  </div>

                  <div className="submit-btn topForm">
                    <input type="submit" name="submit" value="Book Now" className="estimate-form-submit hvr-sweep-to-right" />
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>

          <div className="bottom-banner"></div>
          <p className="bottom-banner-text">
            Welcome to AGM Renovations, your Basement Professionals!
          </p>
        </div>

        <div className="footerInfoBar">
            <div className="itemInfo">
              <b className="border">Ivan A.</b>
              <span className="border">CEO AGM Renovations</span>
            </div>
            <div className="itemInfo">
              <b>Mike Holmes</b>
              <span>TV Star & Contractor</span>
            </div>
        </div>

      </div>
    </section>
  );
}

export default withRouter(EstimateHeader);
